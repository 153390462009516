exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-conditions-index-tsx": () => import("./../../../src/pages/_conditions/index.tsx" /* webpackChunkName: "component---src-pages-conditions-index-tsx" */),
  "component---src-pages-imprint-index-tsx": () => import("./../../../src/pages/imprint/index.tsx" /* webpackChunkName: "component---src-pages-imprint-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-[id]-tsx": () => import("./../../../src/pages/news/[id].tsx" /* webpackChunkName: "component---src-pages-news-[id]-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-offers-[id]-tsx": () => import("./../../../src/pages/offers/[id].tsx" /* webpackChunkName: "component---src-pages-offers-[id]-tsx" */),
  "component---src-pages-offers-tsx": () => import("./../../../src/pages/offers.tsx" /* webpackChunkName: "component---src-pages-offers-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */)
}

